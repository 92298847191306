import { createContext, useContext, type ParentComponent } from "solid-js";
import { createStore } from "solid-js/store";

export type CapencyContext = [
  {
    isEnabled: boolean;
    isLoading: boolean;
    isCapencyChallengeSucceeded: boolean;
    showFailedPanel: boolean;
    isBypassingCapency?: boolean;
    emailMessage?: string;
    phoneMessage?: string;
  },
  {
    setCapencyEnabled?: (enabled: boolean) => void;
    setCapencyLoading?: (loading: boolean) => void;
    setCapencyChallengeSucceeded?: (succeeded: boolean) => void;
    setCapencyShowFailedPanel?: (show: boolean) => void;
    setBypassingCapency?: (bypassing: boolean) => void;
    setEmailMessage?: (message: string) => void;
    setPhoneMessage?: (message: string) => void;
  },
];

const capencyCtx = createContext<CapencyContext>([
  {
    isEnabled: false,
    isLoading: false,
    isCapencyChallengeSucceeded: false,
    showFailedPanel: false,
    isBypassingCapency: false,
    emailMessage: undefined,
    phoneMessage: undefined,
  },
  {},
]);

export const CapencyProvider: ParentComponent<{
  enabled: boolean;
}> = (props) => {
  const [capency, setState] = createStore({
      // eslint-disable-next-line solid/reactivity
      isEnabled: props.enabled || false,
      isLoading: false,
      isCapencyChallengeSucceeded: false,
      showFailedPanel: false,
      isBypassingCapency: false,
      emailMessage: undefined,
      phoneMessage: undefined,
    }),
    store: CapencyContext = [
      capency,
      {
        setCapencyEnabled(enabled: boolean) {
          setState("isEnabled", enabled);
        },
        setCapencyLoading(loading: boolean) {
          setState("isLoading", loading);
        },
        setCapencyChallengeSucceeded(succeeded: boolean) {
          setState("isCapencyChallengeSucceeded", succeeded);
        },
        setCapencyShowFailedPanel(show: boolean) {
          setState("showFailedPanel", show);
        },
        setBypassingCapency(bypassing: boolean) {
          setState("isBypassingCapency", bypassing);
        },
        setEmailMessage(message: string) {
          setState("emailMessage", message);
        },
        setPhoneMessage(message: string) {
          setState("phoneMessage", message);
        },
      },
    ];

  return (
    <capencyCtx.Provider value={store}>{props.children}</capencyCtx.Provider>
  );
};

export function useCapencyContext() {
  const context = useContext(capencyCtx);
  if (context === undefined) {
    throw new Error("useCapencyContext must be used within a CapencyProvider");
  }
  return context;
}
