import { action } from "@solidjs/router";
import type { CapencyResponse } from "./server";
import {
  submitFormModalForm,
  submitFormInPage,
  dispatchSubmit,
  dispatchSubmitInPage,
} from "./server";

export const dispatch = action(dispatchSubmit, "dispatchSubmit");
export const dispatchInPage = action(
  dispatchSubmitInPage,
  "dispatchSubmitInPage",
);
export const submit = action(submitFormModalForm, "submitForm");
export const submitInPage = action(submitFormInPage, "submitInPageForm");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSubmittedAndSuccessful(submission: any) {
  return (
    hasBeenSubmitted(submission) &&
    submission.result?.success === true &&
    !isSubmissionError(submission.result)
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSubmittedButNotValid(submission: any) {
  return (
    hasBeenSubmitted(submission) &&
    "success" in submission.result &&
    (submission.result?.success === false ||
      submission.result?.crm.data.formIsValid === false)
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSubmittedInError(submission: any) {
  return (
    hasBeenSubmitted(submission) &&
    submission.result?.success === false &&
    submission.result?.crmError === true
  );
}

const isSubmissionError = (
  result: SubmissionResult,
): result is SubmissionError => {
  return result.isError === true || result.crm.data.formIsValid === false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasBeenSubmitted(submission: any) {
  const result = submission.result;
  return result && typeof result === "object" && "success" in result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCapencyResponse = (result: any): result is CapencyResponse => {
  return result?.isCapencyChallengeResponse === true;
};

export type SubmissionSuccessful<T> = {
  success: true;
  output: T;
  crm: {
    meta: {
      isPassThroughResponse: boolean;
    };
    data: CrmValues;
  };
};

export type SubmissionFailed = {
  success: false;
  issues: { message: string; path: unknown }[];
};

export type CrmValues = {
  email: string | null;
  errors: unknown[];
  first_contact: string;
  formIsValid: boolean;
  iddemande: string;
  isError: boolean;
  lot_id: string;
  lot_number: string;
  program_name: string;
  project_type: string;
  submission_id: string;
  current_submission_id: string;
};
