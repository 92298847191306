"use server";

import type { DrupalSolidNotFound } from "~/types/common";
import type { FormSystemName } from "~/types/form_system_name";
import type { SubmissionFailed, SubmissionSuccessful } from "./api";

import xss from "xss";

import { safeParse } from "valibot";
import { consola } from "consola";

import { CallbackSchema } from "./CallbackSchema";
import { InstantCallbackSchema } from "./InstantCallbackSchema";
import { AdvisorSchema, AdvisorSchemaWithDesiredArea } from "./AdvisorSchema";
import { ContactUsSchema } from "./ContactUsSchema";
import {
  BlueprintSchema,
  BlueprintSchemaWithDesiredArea,
} from "./BlueprintSchema";
import { FinancialStudySchema } from "./FinancialStudySchema";
import { LeafletSchema, LeafletSchemaWithDesiredArea } from "./LeafletSchema";
import { QualificationSchema } from "./QualificationSchema";
import { ParkingSchema } from "./ParkingSchema";
import { isSubmissionError, sendSubmission } from "~/lib/form_submitter";
import { InPageSchema, InPageSchemaWithMessage } from "./InPageSchema";
import { InvestGuideSchema } from "./InvestGuideSchema";
import { PlotSchema } from "./PlotSchema";
import { SponsorshipSchema } from "./SponsorshipSchema";

import { getLanding, getNode, getProgram } from "~/lib/fetcher";
import { shouldShowDesiredArea } from "~/utils/helper_form";

type SubmissionValues = Record<string, string>;

export type CapencyResponse = {
  isCapencyChallengeResponse: true;
  valid: false;
  data: {
    email: {
      iRet: 1;
      response: string;
      sMessage: string;
      sCodeTraitement: string;
    };
    phone: {
      iRet: number;
      iReturnValue: number;
      MessageCP: {
        sMessage: string;
      };
    };
  };
};

const debug = import.meta.env.VITE_COG_DEBUG_FORMS == "1";

export async function submitFormModalForm(formData: FormData) {
  return await submit(formData);
}

export async function submitFormInPage(formData: FormData) {
  return await submit(formData);
}

async function submit(formData: FormData) {
  return validateAndSendToBackend(formData);
}

async function validateAndSendToBackend(formData: FormData) {
  const values: SubmissionValues = {};

  debug && console.log("formData", formData);

  formData.forEach((value, key) => {
    if (String(value).trim() !== "") {
      // Some fields are duplicated with a _dedupe suffix
      // Because the form storage is a singleton, and as we have multiple forms
      // on the same page, we need to deduplicate the fields
      key = key.endsWith("_dedupe") ? key.replace("_dedupe", "") : key;
      values[key] = xss(String(value));
    }

    // if the key has a point in it, we need to make it a nested object
    // e.g. "field_name.first_name" => { field_name: { first_name: "value" } }
    if (key.includes(".") && String(value).trim() !== "") {
      const [parent, child] = key.split(".");
      values[parent] = {
        ...values[parent],
        [child]: xss(String(value)),
      };
      delete values[key];
    }
  });

  let schema;
  let program;
  let landing;
  let node;

  switch (values.form_id as FormSystemName) {
    case "advisor":
      if (values.node_nid) {
        landing = await getLanding(values.node_nid);
        if (
          !isDrupalSolidNotFoundError(landing) &&
          shouldShowDesiredArea(landing.field_form)
        ) {
          schema = AdvisorSchemaWithDesiredArea;
        } else {
          schema = AdvisorSchema;
        }
      } else {
        schema = AdvisorSchema;
      }

      break;
    case "blueprint":
      if (values.node_nid) {
        landing = await getLanding(values.node_nid);
        if (
          !isDrupalSolidNotFoundError(landing) &&
          shouldShowDesiredArea(landing.field_form)
        ) {
          schema = BlueprintSchemaWithDesiredArea;
        } else {
          schema = BlueprintSchema;
        }
      } else {
        schema = BlueprintSchema;
      }
      break;
    case "callback":
      schema = CallbackSchema;
      break;
    case "contact-us":
      schema = ContactUsSchema;
      break;
    case "financial-study":
      schema = FinancialStudySchema;
      break;
    case "in-page":
      if (values.program_nid) {
        program = await getProgram(values.program_nid);
        if (program.field_form.field_message_display) {
          schema = InPageSchemaWithMessage;
        } else {
          schema = InPageSchema;
        }
      }

      if (values.node_nid) {
        node = await getNode(values.node_nid);
        if (
          !isDrupalSolidNotFoundError(node) &&
          node.field_form?.field_message_display
        ) {
          schema = InPageSchemaWithMessage;
        } else {
          schema = InPageSchema;
        }
      }
      break;
    case "instant-callback":
      schema = InstantCallbackSchema;
      break;
    case "leaflet":
      if (values.node_nid) {
        landing = await getLanding(values.node_nid);
        if (
          !isDrupalSolidNotFoundError(landing) &&
          shouldShowDesiredArea(landing.field_form)
        ) {
          schema = LeafletSchemaWithDesiredArea;
        } else {
          schema = LeafletSchema;
        }
      } else {
        schema = LeafletSchema;
      }
      break;
    case "qualification":
      schema = QualificationSchema;
      break;
    case "parking":
      schema = ParkingSchema;
      break;
    case "guide-invest":
      schema = InvestGuideSchema;
      break;

    case "plot":
      schema = PlotSchema;
      break;

    case "sponsorship":
      schema = SponsorshipSchema;
      break;

    default:
      throw new Error(`Unknown form_id: ${values.form_id}`);
  }

  debug && consola.log("---------------------");
  debug && consola.log("Submitted values", values);
  debug && consola.log("---------------------");

  //debug && consola.log("schema", schema);

  const result = safeParse(schema!, values);
  debug && consola.log("---------------------");

  debug && console.log("result.success", result.success);
  debug && console.log("result.output", result.output);

  if (result.success) {
    debug && console.log("Sending success submission to CRM...");
    const crmCall = await sendSubmission(result.output);

    debug && console.log("crmCall", crmCall.data);

    if (isSubmissionError(crmCall.data)) {
      console.error("CRM error", crmCall.data);
      return {
        success: false,
        crmError: true,
        issues: [
          {
            path: "crm",
            message: "Erreur lors de l'envoi du formulaire",
          },
        ],
        debug: crmCall?.data,
      } as SubmissionFailed & { crmError: true };
    }

    return {
      success: result.success,
      output: result.output,
      crm: crmCall.data,
    } as SubmissionSuccessful<unknown>;
  }

  const issues = result.issues?.map(filterIssuesProps);
  //debug && console.log("issues", issues);

  return {
    success: false,
    issues: issues,
  } as SubmissionFailed;
}

// Function to remove properties that are functions from an object
function filterIssuesProps<T extends Record<string, unknown>>(
  obj: T,
): Partial<T> {
  const newObj = { ...obj } as T; // Create a shallow copy of the object

  Object.keys(newObj)
    .filter((key) => !["path", "message"].includes(key))
    .forEach((key) => delete newObj[key]);

  return newObj;
}

export async function submitCapencyCheck(
  formData: FormData,
): Promise<CapencyResponse> {
  console.log("submitCapencyCheck formData", formData);
  const debugFetcher = import.meta.env.VITE_COG_DEBUG_FETCHER == 1;

  const body = {
    email: formData.get("email"),
    phone: formData.get("phone"),
  };

  const headers = new Headers();
  headers.append("User-Agent", "chrome");
  headers.append("Content-Type", "application/json");

  if (import.meta.env.VITE_COG_BACKEND_AUTH_LOGIN.length > 0) {
    headers.append(
      "Authorization",
      "Basic " +
        btoa(
          import.meta.env.VITE_COG_BACKEND_AUTH_LOGIN +
            ":" +
            import.meta.env.VITE_COG_BACKEND_AUTH_PASSWORD,
        ),
    );
  }

  const url = `${import.meta.env.VITE_COG_BACKEND_BASE_URL}/capency/validate`;

  try {
    debugFetcher && console.info(`Fetching ${url}`);
    const response = await fetch(url, {
      headers,
      method: "POST",
      body: JSON.stringify(body),
    });

    const ret = await response.json();
    debugFetcher && console.log("capencyCheck ret", ret);
    return ret;
  } catch (e) {
    console.error(e);
    // TODO: handle error
    return { valid: false };
  }
}

export async function dispatchSubmit(formData: FormData) {
  if (
    formData.has("perform_capency_check") &&
    formData.get("perform_capency_check") === "1"
  ) {
    return await submitCapencyCheck(formData);
  } else {
    return await validateAndSendToBackend(formData);
  }
}

export async function dispatchSubmitInPage(formData: FormData) {
  if (
    formData.has("perform_capency_check") &&
    formData.get("perform_capency_check") === "1"
  ) {
    return await submitCapencyCheck(formData);
  } else {
    return await validateAndSendToBackend(formData);
  }
}

export function isDrupalSolidNotFoundError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any,
): response is DrupalSolidNotFound {
  return (
    typeof response === "object" && "error" in response && response.code === 404
  );
}
