import type { HiddenFormFields } from "~/types/form";
import { InputTypeHidden } from "~/components/Forms/Fields/Base/InputTypeHidden";
import { optional, string } from "valibot";

export function SharedHiddenFieldsSchema() {
  return {
    perform_capency_check: optional(string()),
    base_email: optional(string()),
    campaign: optional(string()),
    source: optional(string()),
    medium: optional(string()),
    term: optional(string()),
    content: optional(string()),
    referrer: optional(string()),

    program_nid: optional(string()),
    lot_id: optional(string()),
    node_nid: optional(string()),
    regulation_tid: optional(string()),

    user_latitude: optional(string()),
    user_longitude: optional(string()),

    search_typo: optional(string()),
    search_budget: optional(string()),
    submission_id: optional(string()),
    var_first_contact_context: optional(string()),
    gclid: optional(string()),
  };
}

export function SharedHiddenFields(props: HiddenFormFields) {
  return (
    <>
      {/* Disables submit return key press: none JavaScript solution. */}
      <button type="submit" disabled style="display: none" aria-hidden="true" />
      {/* Hidden fields which are used on every form. */}
      <InputTypeHidden
        name="perform_capency_check"
        data-test="perform_capency_check"
        value={props.performCapencyCheck}
      />
      <InputTypeHidden
        name="form_id"
        data-test="form_id"
        value={props.form_id}
      />
      <InputTypeHidden
        name="base_email"
        data-test="base_email"
        value={props.defaultValues?.base_email}
      />
      <InputTypeHidden
        name="campaign"
        data-test="campaign"
        value={props.defaultValues?.campaign}
      />
      <InputTypeHidden
        name="source"
        data-test="source"
        value={props.defaultValues?.source}
      />
      <InputTypeHidden
        name="medium"
        data-test="medium"
        value={props.defaultValues?.medium}
      />
      <InputTypeHidden
        name="term"
        data-test="term"
        value={props.defaultValues?.term}
      />
      <InputTypeHidden
        name="content"
        data-test="content"
        value={props.defaultValues?.content}
      />
      <InputTypeHidden
        name="referrer"
        data-test="referrer"
        value={props.defaultValues?.referrer}
      />
      <InputTypeHidden
        name="program_nid"
        data-test="program_nid"
        value={props.defaultValues?.program_nid}
      />
      <InputTypeHidden
        name="lot_id"
        data-test="lot_id"
        value={props.defaultValues?.lot_id}
      />
      <InputTypeHidden
        name="node_nid"
        data-test="node_nid"
        value={props.defaultValues?.node_nid}
      />
      <InputTypeHidden
        name="regulation_tid"
        data-test="regulation_tid"
        value={props.defaultValues?.regulation_tid}
      />
      <InputTypeHidden
        name="user_latitude"
        data-test="user_latitude"
        value={props.defaultValues?.user_latitude}
      />
      <InputTypeHidden
        name="user_longitude"
        data-test="user_longitude"
        value={props.defaultValues?.user_longitude}
      />
      <InputTypeHidden
        name="search_typo"
        data-test="search_typo"
        value={props.defaultValues?.search_typo}
      />
      <InputTypeHidden
        name="search_budget"
        data-test="search_budget"
        value={props.defaultValues?.search_budget}
      />
      <InputTypeHidden
        name="submission_id"
        data-test="submission_id"
        value={props.defaultValues?.submission_id}
      />
      <InputTypeHidden
        name="var_first_contact_context"
        data-test="var_first_contact_context"
        value={props.defaultValues?.var_first_contact_context}
      />
      <InputTypeHidden
        name="gclid"
        data-test="gclid"
        value={props.defaultValues?.gclid}
      />
    </>
  );
}
